const links = [
  {
    title: 'My Bookings',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Customer',
    noAdmin: true,
  },
  {
    title: 'Book a Charter',
    route: 'book-trip',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'Customer',
    noAdmin: true,
  },
  {
    title: 'Trip Preparations',
    route: 'trip-preparations',
    icon: 'InfoIcon',
    action: 'read',
    resource: 'Customer',
    noAdmin: true,
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
    action: 'manage',
    resource: 'Captain',
  },
  {
    title: 'Announcements',
    route: 'announcements',
    icon: 'AlertCircleIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: "Bookings",
    icon: "ExternalLinkIcon",
    children: [
      {
        title: 'Charters',
        route: 'bookings',
        icon: 'BookIcon',
        action: 'manage',
        resource: 'Captain',
      },
      {
        title: 'Inshore Trips',
        route: 'inshore',
        icon: 'BookIcon',
        action: 'manage',
        resource: 'Admin',
      },
      {
        title: 'Harbor Cruises',
        route: 'cruise',
        icon: 'BookIcon',
        action: 'manage',
        resource: 'Admin',
      }
    ]
  },
  {
    title: 'Boats',
    route: 'boats',
    icon: 'AnchorIcon',
    action: 'manage',
    resource: 'Captain',
  },
  {
    title: 'Trips',
    route: 'trips',
    icon: 'CompassIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: 'Customers',
    route: 'customers',
    icon: 'UsersIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: 'Daily Log',
    route: 'daily-log',
    icon: 'Edit3Icon',
    action: 'manage',
    resource: 'Captain'
  },
  {
    title: 'Daily Catch',
    route: 'daily-catch',
    icon: 'Edit3Icon',
    action: 'manage',
    resource: 'Captain'
  },
  {
    title: 'Add Customer',
    icon: 'UserPlusIcon',
    route: 'new-customer',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: 'Add Booking',
    icon: 'FilePlusIcon',
    route: 'new-booking',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: 'FAQ',
    icon: 'HelpCircleIcon',
    route: 'faq',
    action: 'read',
    resource: 'Customer',
    noAdmin: true,
  },
  {
    title: 'Announcement',
    icon: 'Volume2Icon',
    route: '#',
    modal: 'announcements-static-modal',
    action: 'read',
    resource: 'Customer',
    noAdmin: true,
  },
  {
    title: 'Profile',
    icon: 'UserIcon',
    route: 'profile',
    action: 'read',
    resource: 'Customer',
  },
  {
    title: 'Logout',
    icon: 'LogOutIcon',
    route: 'logout',
    action: 'read',
    resource: 'Customer',
  },
]

export default links
