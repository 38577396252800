import axios from '@/libs/axios'
import JwtService from './jwtService'

function useJwt(axiosIns) {
  const jwt = new JwtService(axiosIns, {})

  return {
    jwt,
  }
}

const { jwt } = useJwt(axios)
export default jwt
